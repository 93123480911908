const blogMenu = document.getElementById('blog-menu');
const floatingBlogMenu = document.getElementById('floating-blog-menu');

window.onscroll = function () {
  let elemRect = blogMenu.getBoundingClientRect();
  if (elemRect.top < -50) {
    floatingBlogMenu.classList.add('show');
  } else {
    floatingBlogMenu.classList.remove('show');
  }
};
